import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { trigger, style, animate, transition } from '@angular/animations';
import { HttpEventType } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-whiteboard',
  templateUrl: './whiteboard.component.html',
  styleUrls: ['./whiteboard.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('0.35s ease-out',
              style({ height: 100, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 100, opacity: 1 }),
            animate('0.35s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class WhiteboardComponent implements OnInit {
  menuState: boolean = false
  drawingMode: string = 'brush';
  brushSize: number = 5;
  brushColor: string;
  selectedColor: string;
  brushUrl = "url('./assets/brush.ico')";
  eraserUrl = "url('./assets/eraser.ico')";
  @ViewChild('canvas') canvas: ElementRef;
  private ctx: CanvasRenderingContext2D;
  private position: DOMRect;
  strokePicker: boolean = false;
  colorPicker: boolean = false;
  lastImage: any;
  isLoading: boolean;
  progress: number;
  file: any;
  whiteboardImage: any;
  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.brushColor = 'black'
    this.selectedColor = 'black'
  }
  dashboardUrl(){
    window.location.href=`${environment.dashboardFrontEndUrl}`;
    }
  ngAfterViewInit() {
    const tabBar = document.getElementById('tabBar');
    if (tabBar) {
      tabBar.style.display = 'none';
    }
    this.canvas.nativeElement.width = window.innerWidth -10
    this.canvas.nativeElement.height = window.innerHeight - 200
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.position = this.canvas.nativeElement.getBoundingClientRect();
    this.position.x = 0
    this.drawWhiteBoard()
  }
  drawWhiteBoard() {
    let lastX;
    let lastY;
    let moving = false;
    this.ctx.fillStyle = "white"
    this.ctx.fillRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);

    this.canvas.nativeElement.addEventListener('pointerdown', (ev) => {
      if (this.strokePicker){this.strokePicker = false}
      moving = true;
      lastX = ev.pageX - this.position.x;
      lastY = ev.pageY - this.position.y;

    })

    this.canvas.nativeElement.addEventListener('pointerup', (ev) => {
      moving = false;
      lastX = ev.pageX - this.position.x;
      lastY = ev.pageY - this.position.y;
      this.lastImage = this.canvas.nativeElement.toDataURL();

    })
    this.canvas.nativeElement.addEventListener('touchend', (ev) => {
      if (this.strokePicker){this.strokePicker = false}
      moving = false;
      lastX = ev.pageX - this.position.x;
      lastY = ev.pageY - this.position.y;
      this.lastImage = this.canvas.nativeElement.toDataURL();

    })

    this.canvas.nativeElement.addEventListener('mousemove', (ev) => {
      if (moving) {

        let currentX = ev.pageX - this.position.x;
        let currentY = ev.pageY - this.position.y;

        this.ctx.beginPath();
        this.ctx.lineJoin = "round";
        this.ctx.moveTo(lastX, lastY);
        this.ctx.lineTo(currentX, currentY);
        this.ctx.closePath();
        this.ctx.strokeStyle = this.brushColor;
        this.ctx.lineWidth = this.brushSize;
        this.ctx.stroke();

        lastX = currentX;
        lastY = currentY;

      }

    });
    this.canvas.nativeElement.addEventListener('touchmove', (ev) => {
      if (moving) {
        let currentX = ev.touches[0].pageX - this.position.x;
        let currentY = ev.touches[0].pageY - this.position.y;

        this.ctx.beginPath();
        this.ctx.lineJoin = "round";
        this.ctx.moveTo(lastX, lastY);
        this.ctx.lineTo(currentX, currentY);
        this.ctx.closePath();
        this.ctx.strokeStyle = this.brushColor;
        this.ctx.lineWidth = this.brushSize;
        this.ctx.stroke();

        lastX = currentX;
        lastY = currentY;

      }

    });
  }
  drawingModeToggle(data) {
    this.drawingMode = data;
    if (this.drawingMode === 'brush') {
      this.brushColor = this.selectedColor
    } else {
      this.selectedColor = this.brushColor
      this.brushColor = 'white';
    }
  }
  clearCanvas() {
    this.lastImage = ''
    this.ctx.fillStyle = 'white'
    this.ctx.fillRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
  }
  saveImage() {
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = this.canvas.nativeElement.toDataURL();
    var now = new Date().getTime();
    link.download = "board" + now + ".png";
    document.body.appendChild(link);
    link.click();
    link.remove();
    // this.pdfFileUpload()
  }
  changeBrushColor(color) {
    switch (color) {
      case 'black': this.brushColor = 'black';
        this.selectedColor = this.brushColor;
        this.drawingModeToggle('brush');
        this.colorPicker = false;
        break;
      case 'red': this.brushColor = 'red';
        this.selectedColor = this.brushColor;
        this.drawingModeToggle('brush');
        this.colorPicker = false;
        break;
      case 'green': this.brushColor = 'green';
        this.selectedColor = this.brushColor;
        this.drawingModeToggle('brush');
        this.colorPicker = false;
        break;
      default: this.brushColor = 'black';
        this.selectedColor = this.brushColor
    }
  }
  onIonChange(e) {
    console.log(e.target.value)
    this.brushSize = e.target.value;
  }
  toggleToolbar(tool) {
    if (tool == 'color') {
      this.colorPicker ? this.colorPicker = false : this.colorPicker = true;
      this.strokePicker = false
    } else {
      this.strokePicker ? this.strokePicker = false : this.strokePicker = true;
      this.colorPicker = false;
    }
  }
  onResize(e) {
    console.log("resize triggered");

    // console.log(window.innerWidth,window.innerHeight,"window");
    // console.log(e.target.innerWidth,e.target.innerHeight);

    this.canvas.nativeElement.width = window.innerWidth -10
    this.canvas.nativeElement.height = window.innerHeight - 200
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.position = this.canvas.nativeElement.getBoundingClientRect();
    this.position.x = 0
    this.drawWhiteBoard()
    var image = new Image();
    image.src = this.lastImage
    // this.ctx.drawImage(image, 0, 0);
    // this.ctx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    var scale = Math.min(this.canvas.nativeElement.width / image.width, this.canvas.nativeElement.height / image.height);
    var x = (this.canvas.nativeElement.width / 2) - (image.width / 2) * scale;
    var y = (this.canvas.nativeElement.height / 2) - (image.height / 2) * scale;
    this.ctx.drawImage(image, x, y, image.width * scale, image.height * scale);

  }
  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }


}
