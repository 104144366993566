import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WhiteboardService {

  constructor(private http:HttpClient) {

   }


  public getUserDetails(userId): Observable<any> {
    return this.http.get(`${environment.authUrl}users/${userId}`)
  }

  logout(){
    localStorage.removeItem("a")
    localStorage.removeItem("userInfo")
    window.open('https://uat.db.moonhive-server.in.net/#/home/dashboard/dashboard', "_self")
   //  window.open('https://sso.moonhive-server.in.net/', "_self")
 }

}
