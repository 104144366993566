<div style="height: 98vh;overflow: hidden;">
  <div class="toolbar" style="justify-content: space-between;padding:25px;align-items: center;">
    <div class="leftdiv">
      <span style="cursor:pointer;" (click)="dashboardUrl()">
        <img width="90px" class="tools-inner" src="assets/icons/logowww.svg" alt="">
      </span>
      &nbsp;<span style="font-size: 28px;">|</span>&nbsp;
          <span>Whiteboard</span>
    </div>
    <div class="righdiv">
      <div class="clearButtonDiv">
        <p class="clear-button" (click)="clearCanvas()" style="height: 20px;">Clear</p>
      </div>
      <div (click)="saveImage()" class="custom-btn-primary" color="danger" expand="block">
        Save
      </div>
    </div>
  </div>

  <div class="row d-flex flex-column" style="background-color:#F9F9FC;height: 100vh;"
    (window:resize)="onResize($event)">
    <div class="clearButtonDiv">
      <!-- <p class="clear-button" (click)="clearCanvas()" style="height: 20px;">Clear</p> -->
    </div>
    <canvas #canvas id="myCanvas"></canvas>
  </div>
</div>
<div class="tools-div">

  <div class="toolbar" *ngIf="colorPicker" style="height: 100px;" [@inOutAnimation]>
    <img width="25" class="tools-inner" src="assets/icons/record-circle-fill-black.svg" alt=""
      (click)="changeBrushColor('black')">
    <img width="25" class="tools-inner" src="assets/icons/record-circle-fill-green.svg" alt=""
      (click)="changeBrushColor('green')">
    <img width="25" class="tools-inner" src="assets/icons/record-circle-fill-red.svg" alt=""
      (click)="changeBrushColor('red')">
    <img width="30" src="assets/icons/arrow-down-s-line (1).svg" alt="" (click)="colorPicker =false">
  </div>
  <div class="toolbar" *ngIf="strokePicker" style="height: 100px;" [@inOutAnimation]>
    <input type="range" min="1" max="30" style="width: 40%;" [value]="brushSize" (change)="onIonChange($event)" class="form-range"
      id="customRange1">
    <img width="30" src="assets/icons/arrow-down-s-line (1).svg" alt="" (click)="strokePicker =false">

  </div>
  <div class="toolbar">
    <div class="tools" [style.color]="drawingMode == 'brush' ? '#000' : '#666666'" (click)="drawingModeToggle('brush')">
      <img width="25" [src]="drawingMode == 'brush' ?'assets/icons/pencil-line (1).svg':'assets/icons/pencil-line.svg'"
        alt="">
      <label>Pen</label>
    </div>
    <div class="tools" [style.color]="drawingMode == 'erase' ? '#000' : '#666666'" (click)="drawingModeToggle('erase')">
      <img width="25" [src]="drawingMode == 'erase' ?'assets/icons/eraser-fill (1).svg':'assets/icons/eraser-fill.svg'"
        alt="">
      <label>Eraser</label>
    </div>
    <div class="tools" (click)="toggleToolbar('color')" [style.color]="colorPicker ? '#000' : '#666666'">
      <i name="ellipse" [style.color]="brushColor == 'white' ? selectedColor : brushColor"></i>
      <img width="25"
        [src]=" brushColor == 'black' || brushColor == 'white'? 'assets/icons/record-circle-fill-black.svg':brushColor == 'green'?'assets/icons/record-circle-fill-green.svg':'assets/icons/record-circle-fill-red.svg'"
        alt="">
      <label>Color</label>
    </div>
    <div class="tools" (click)="toggleToolbar('stroke')" [style.color]="strokePicker ? '#000' : '#666666'">
      <p
        style="font-size: 35px;display: flex;height: 28px;margin: 0;font-weight: 600;/* justify-content: center; */align-items: center;">
        -</p>
      <label>Stroke</label>
    </div>
  </div>
</div>
