import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { take } from 'rxjs';
import { WhiteboardService } from './whiteboard/whiteboard.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'whiteboard';
  paramsObject: any;

  constructor(private router:Router, private route:ActivatedRoute, private whiteboardService:WhiteboardService){
    this.router.events.pipe(take(21)).subscribe(
      (event: RouterEvent) => {
        if (event instanceof NavigationEnd) {
          this.route.queryParamMap.pipe(take(1)).subscribe((params: any) => {
            this.paramsObject = { ...params.keys, ...params };
            console.log(this.paramsObject);
            if (Object.keys(this.paramsObject.params).length) {
              if (this.paramsObject?.params?.access) {
                localStorage.setItem('a', this.paramsObject.params.access)
              }
              if (this.paramsObject?.params?.uid) {
                this.whiteboardService.getUserDetails(this.paramsObject?.params?.uid).subscribe((e) => {
                  localStorage.setItem('userInfo', JSON.stringify(e))
                  // this.getAllOrganizations(e)
                })
              }
            }else{
              this.whiteboardService.getUserDetails(JSON.parse(localStorage.getItem('userInfo'))?.uid || 'invalid user').subscribe({
                next: (e) => {
                  localStorage.setItem('userInfo', JSON.stringify(e))
                  // this.getAllOrganizations(e)
                },
                error: () => {
                  this.whiteboardService.logout()
                }
              })
            }
          })
        }
      })
  }


}
